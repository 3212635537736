import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { LicenseInfo } from "@mui/x-license-pro";
import { Helmet } from "react-helmet";
import { EAdminPermission } from "@DCraig-Asig/eazy-resources";

import Sidenav from "components/Sidenav";
import routes from "routes";
import { setMiniSidenav, useMaterialUIController } from "context";
import { IRoute } from "interfaces";
import { getToken } from "utils/utilsFunctions";

import miniLogo from "assets/images/favicon_128.png";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import "react-toastify/dist/ReactToastify.css";
import { Endpoints, getRequest } from "./helpers/api";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const PrivateRoute = (props: {
  permissions?: EAdminPermission[];
  noAccessRedirectTo?: string;
}): any => {
  const [loading, setLoading] = useState(true);
  const [isValidSession, setIsValidSession] = useState(false);
  const [permissions, setPermissions] = useState<EAdminPermission[]>();

  useEffect(() => {
    getRequest(`${Endpoints.USER_SESSION}`)
      .then((res) => {
        setIsValidSession(true);
        setPermissions(res.role?.permissions);
      })
      .catch(() => {
        toast.error("Session expired");

        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        setIsValidSession(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const loggedIn = getToken();

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return <>Loading</>;
  }

  if (isValidSession) {
    if (
      !!props?.permissions &&
      !permissions.some((p) => props.permissions?.includes(p))
    ) {
      return <Navigate to={props?.noAccessRedirectTo || "/dashboard"} />;
    }
    return <Outlet />;
  }

  return <Navigate to="/login" />;
  // return loggedIn ? (
  //   loading ? (
  //     <>Loading</>
  //   ) : isValidSession ? (
  //     <Outlet />
  //   ) : (
  //     <Navigate to="/login" />
  //   )
  // ) : (
  //   <Navigate to="/login" />
  // );
};

const getRoutes = (allRoutes: IRoute[]): any => {
  return allRoutes.map((route: IRoute) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return route.private ? (
        <Route
          path={route.route}
          key={route.key}
          element={
            <PrivateRoute
              permissions={route.permissions}
              noAccessRedirectTo={route.noAccessRedirectTo}
            />
          }
        >
          <Route path={route.route} element={route.component} key={route.key} />
        </Route>
      ) : (
        <Route path={route.route} element={route.component} key={route.key} />
      );
    }

    return null;
  });
};

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { layout, miniSidenav, darkMode } = controller;
  const { pathname } = useLocation();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const navigate = useNavigate();

  // Setting page scroll to 0 when changing  the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    if (
      !pathname.includes("login") &&
      !pathname.includes("logout") &&
      !pathname.includes("onboarding") &&
      !pathname.includes("reset-password-request") &&
      !pathname.includes("reset-password")
    ) {
      let count = 0;

      const interval = setInterval(() => {
        count++;

        if (count === 1800) {
          clearInterval(interval);
          navigate("/logout");
        }
      }, 1000);

      document.addEventListener("click", () => {
        count = 0;
      });

      return () => {
        clearInterval(interval);
      };
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {process.env.NEXT_PUBLIC_NODE_ENV !== "production" && (
        <Helmet>
          <meta name="googlebot" content="noindex,nofollow" />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      )}
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          color="primary"
          brandName="Eazy Insurance"
          brand={miniLogo}
          routes={routes}
        />
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}
