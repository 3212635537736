export default {
  menu: {
    dashboard: "Dashboard",
    account: "Contul meu",
    logout: "Deconecteaza-te",
    users: "Utilizatori BO",
    userslist: "Lista utilizatori",
    adduser: "Adauga utilizator",
    roles: "Roluri",
    roleslist: "Lista roluri",
    addrole: "Adauga rol",
    myaccount: "Contul meu",
    roledetails: "Detalii rol",
    userdetails: "Detalii utilizator",
    petitions: "Petitii",
    addPetitions: "Adauga petitie",
    petitionsList: "Lista petitii",
    petitionsQuarterlyReportsList: "Lista rapoarte",
    petitionDetails: "Detalii petitie",
    registry: "Registru petitii",
    payments: "Plati",
    cardpaymentslist: "Plati cu cardul",
    oppaymentslist: "Plati prin OP",
    inchidereluna: "Inchidere luna",
    inchiderelunadepadmin: "Inchidere luna",
    inchiderelunadetails: "Inchidere luna",
  },
  newPetition: {
    title: "Adauga o petitie noua",
    subTitle: "Completeaza formularul pentru a adauga o petitie noua",
    petentTitle: "Petent",
    petitionTitle: "Petitie",
    originalPetitionId: "Nr Petitie Identica",
    petitionAgentId: "Responsabil Solutionare",
    institution: "Nr. si Data Institutie",
    petitionerName: "Nume & Prenume",
    petitionerIdentificationNumber: "CNP",
    petitionerAddress: "Adresa",
    petitionerEmail: "Email",
    petitionerPhoneNumber: "Telefon",
    solicitant: "Solicitant",
    petitionerType: "Calitate",
    petitionType: "Tip Petitie",
    petitionObject: "Obiectul Petitiei",
    insuranceClass: "Clasa de Asigurare",
    inputChannel: "Canalul de Receptie",
    petitionContent: "Text Petitie",
    petitionNumber: "Nr petitie",
    policyNumber: "Nr Polita Asigurare",
    claimNumber: "Nr Dosar Dauna",
    urgency: "Prioritate",
    receptionDate: "Data Petitiei",
    resolutionDate: "Data Solutionarii Petitiei",
    internalDeadline: "Data Limita Solutionare",
    receptionChannel: "Canal Receptie",
    sendPetition: "Trimite petitie",
    country: "Tara provenienta",
    status: "Status",
    petitionAgentName: "Responsabil solutionare",
    petitionResolutionEndMode: "Modalitate de finalizare petitie",
    petitionResolutionEndReason:
      "Motivul pentru care petiţia nu a fost soluţionată favorabil petentului",
    petitionObservations: "Observatii",
    asfNumber: "Numar inregistrare ASF",
    asfRegistrationDate: "Data inregistrarii ASF",
    uploadDocuments: {
      title: "Incarca Documente",
      petitionClientFileTitle: "Fisierul clientului",
      notaFundamentareTitle: "Fisierul Nota Fundamentare",
      mesajAsfTitle: "Fisierul Mesaj Asf",
    },
    validations: {
      requiredPetitionerName: "Numele si prenumele sunt obligatorii",
      petitionerIdentificationNumber: "CNP trebuie sa contina 13 cifre",
      invalidIdentificationNumber: "CNP Invalid",
      requiredPhoneNumber: "Numarul de telefon este obligatoriu",
      invalidPhoneNumber: "Numar telefon incorect",
      invalidEmailAddress: "Adresa de email este incorecta",
      requiredSolicitant: "Alege tipul de solicitant",
      requiredPetitionerType: "Alege calitatea petentului",
      requiredReceptionDate: "Alege data petitiei",
      requiredInternalDeadline: "Alege data limita a petitiei",
      requiredPetitionObject: "Alege obiectul petitiei",
      requiredInsuranceClass: "Alege clasa de asigurare",
      petitionContent: "Te rugam completeaza continutul petitiei",
      requiredSource: "Alege canalul de receptie",
      requiredDetails: "Scrie detalii despre petitie",
      requiredUrgency: "Alege cat de prioritara este petitia",
      requiredAsfRegistrationDate: "Alege data inregistrarii ASF",
      requiredAsfNumber: "Alege numarul inregistrarii ASF",
      stringField: "Campul trebuie sa fie format text",
      numberField: "Valoarea trebuie sa fie un numar",
      dateField: "Valoarea trebuie sa fie o data",
    },
  },
  petitions: {
    title: "Lista petitii",
    registry: {
      title: "Registrul Unic al Petitiilor",
    },
  },
  petitionDetails: {
    titleSection: "Continutul Petitiei",
    title: "Petitia nr. ",
    petentSectionTitle: "Petent",
    petitionProvenienceSectionTitle: "Provenienta Petitie",
    petitionSectionTitle: "Petitie",
    resolveSectionTitle: "Solutionare",
    documentsSectionTitle: "Documente incarcate",
    petitionContent: {
      content: "Text petitie",
      observations: "Observatii",
    },
    petitionUpdates: {
      title: "Actualizari",
      petitionStatus: "Status",
      registryNumberIO: "Nr. Registru intrari-iesiri",
      updateNotes: "Notite actualizare",
      attachments: "Atasamente",
      internalDeadline: "Data solutionarii intern",
      petitionResolveStatus: "Stadiul Petitiei",
      resolutionDate: "Data solutionarii petitiei",
      petitionAgentId: "Responsabil",
      petitionResolutionEndReason:
        "Motivul pentru care petitia nu a fost solutionata favorabil",
      petitionResolutionEndMode: "Modalitate de finalizare petitie",
      observations: "Observatii",
      observationsInfo:
        'In situatia in care Petitia este semnalata ca “Petitie Identica", campul de observatii este pre-completat cu nr de inregistrare si data petitiei la care se face referire (petitia considerata identica).',
      validations: {
        requiredPetitionResolveStatus: "Statusul este obligatoriu",
        requiredPetitionAgentId: "Agentul este obligatoriu",
        requiredOriginalPetitionId:
          "Numarul petitiei identice este obligatoriu",
        requiredResolutionDate: "Data solutionarii este obligatorie",
        requiredPetitionResolutionEndMode: "Modalitatea este obligatorie",
        requiredPetitionResolutionEndReason: "Motivul este obligatoriu",
      },
    },
    petitionClientResponse: {
      title: "Raspuns Client",
    },
  },
  roles: {
    title: "Roluri",
    roleName: "Nume rol",
    createdAt: "Creat la",
    createdBy: "Creat de",
    actions: "Actiuni",
    addRoleTitle: "Adauga rol nou",
    addRoleDescription: "Completeaza formularul pentru a adauga un rol nou",
    roleInfoStepTitle: "Detalii rol",
    roleInfoStepHelpDescription:
      "Completeaza numele pe care vrei sa-l aiba rolul",
    roleInputLabel: "Nume rol",
    roleSelectPermissions: "Permisiuni",
    roleSelectPermissionsTitle: "Permisiuni",
    roleSelectPermissionsDescription: "Selecteaza permisiunile pentru noul rol",
    roleAddReview: "Revizuire",
    roleAddReviewTitle: "Revizuire",
    roleAddReviewDescription:
      "Verifica informatiile despre noul rol inainte de a fi adaugat",
    next: "Pasul urmator",
    back: "Inapoi",
    add: "Adauga",
    userWithRole: "Utilizatori cu rolul",
    roleChangelogTableTitle: "Changelog pentru rolul",
    roleCurrentPermissions: "Permisiuni curente",
    noCurrentPermissions: "Nu exista permisiuni curente",
    roleAvailablePermissions: "Permisiuni disponibile pentru acest rol",
    noAvailablePermissions: "Nu exista permisiuni disponibile pentru acest rol",
  },
  users: {
    addUserTitle: "Adauga utilizator nou",
    userInfoStepTitle: "Detalii utilizator",
    rolesInfoStepTitle: "Roluri utilizator",
    roleAddReviewTitle: "Revizuire",
    addUserDescription:
      "Completeaza formularul pentru a adauga un utilizator nou",
    userDetailsDescription: "Completeaza detaliile utilizatorului",
    addUserRoleDescription: "Asigneaza un rol utilizatorului",
    nameInputLabel: "Nume utilizator",
    emailInputLabel: "Email utilizator",
    roleInputLabel: "Nume rol",
    statusInputLabel: "Status",
    createdAtInputLabel: "Data creare",
    lastLoginInputLabel: "Ultima logare",
    validationRequired: "este necesar",
    validationInvalid: "este invalid",
    title: "Utilizatori",
    userChangelogTableTitle: "Changelog pentru utilizatorul",
    userAddReviewDescription:
      "Verifica informatiile despre noul utilizator inainte de a fi adaugat",
    deactivateUserTitle: "Dezactivare utilizator",
    deactivateUserDescription:
      "Sunteți sigur că doriți să dezactivați utilizatorul",
    dropdownUsersDescription: "Selectați cărui utilizator asignați task-urile:",
  },
  payments: {
    cardPayments: {
      title: "Plati cu cardul",
    },
    opPayments: {},
  },
  dataTable: {
    noResults: "niciun rezultat",
    entriesPerPage: "intrari pe pagina",
    search: "Cauta",
    from: "De la",
    to: " la",
    of: "din ",
    entries: "intrari",
    filters: {
      export: "Export CSV",
      resetFilters: "Reseteaza filtrele",
    },
    unassignedLabel: "NEALOCAT",
  },
  login: {
    signIn: "Intra in contul tau",
    description: "Introduceti email-ul si parola pentru a va autentifica",
    emailLabel: "Email",
    passwordLabel: "Parola",
    confirmPasswordLabel: "Confirma parola",
    rememberMe: "Tine-ma minte",
    buttonLabel: "Autentifica-te",
    resetPasswordLabel: "Ti-ai uitat parola?",
  },
  twoFa: {
    title: "2 Factor Authentication",
    description: "Introduceti codul din 6 cifre generat de autentificator",
    buttonLabel: "Autentifica-te",
  },
  setPassword: {
    title: "Seteaza parola",
    description: "Introduceti parola pentru noul cont",
    passwordLabel: "Parola",
    confirmPasswordLabel: "Confirma parola",
    buttonLabel: "Trimite",
  },
  setTwoFa: {
    title: "2 Factor Authentication",
    description: "Scanati codul QR cu ajutorul aplicatiei Google Authenticator",
    buttonLabel: "Trimite",
  },
  requestPasswordReset: {
    title: "Ti-ai uitat parola?",
    description: "Introduceti adresa de email pentru a iti reseta parola",
    buttonLabel: "Trimite",
  },
  changePassword: {
    title: "Alege noua parola",
    description: "Introduceti noua parola a contului",
    buttonLabel: "Trimite",
  },
  toastrMessages: {
    successLogin: "Te-ai autentificat cu succes!",
    successAddNewRole: "Rolul a fost adaugat cu succes!",
    successAddNewPetition: "Petitia a fost adaugata cu succes!",
    successUpdateRole: "Rolul a fost modificat cu succes!",
    successUpdatePetition: "Petitia a fost modificata cu succes!",
    successAddNewUser: "Userul a fost adaugat cu succes!",
    successUpdateUser: "Userul a fost modificat cu succes!",
    differentConfirmPassword: "Parolele nu corespund",
    inactiveAccountError:
      "Your account is inactive. Please contact the admin app",
    resetPasswordRequestSucces:
      "Cererea de schimbare a parolei a trimisa cu succes.",
  },
};
