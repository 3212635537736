import Card from "@mui/material/Card";
import { Done, Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import UserForm from "./components/UserForm";
import { IUser } from "interfaces";
import { translate } from "translations/i18n";
import { Endpoints, getRequest, patchRequest } from "helpers/api";
import DeleteUser from "./components/DeleteUser";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(
      `${translate("users.nameInputLabel")} ${translate(
        "users.validationRequired"
      )}`
    ),
  email: yup
    .string()
    .email(
      `${translate("users.emailInputLabel")} ${translate(
        "users.validationRequired"
      )}`
    )
    .required(
      `${translate("users.emailInputLabel")} ${translate(
        "users.validationInvalid"
      )}`
    ),
});

const UserDetails = () => {
  const [user, setUser] = useState<IUser>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const location = useLocation();
  const state = location.state as { edit?: boolean };
  const params = useParams();
  const { id } = params;

  const getData = useCallback(() => {
    getRequest(`${Endpoints.USER}/${id}`)
      .then((res) => {
        setUser(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getData();
    state && state?.edit && setIsEditMode(true);
  }, []);

  const { values, initialValues, handleChange, errors, submitForm, ...rest } =
    useFormik({
      initialValues: {
        name: user?.name || "",
        email: user?.email || "",
        role: user?.role || null,
        createdAt: user?.createdAt || "",
        status: user?.status || "",
        lastLoggedIn: user?.lastLoggedIn || "",
      },
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        // Taking only the edited values without role
        const diff = Object.entries(values).reduce((acc, [key, value]) => {
          // @ts-ignore
          const hasChanged = initialValues[key] !== value;
          if (hasChanged && key !== "role") {
            // @ts-ignore
            acc[key] = value;
          }
          return acc;
        }, {});

        patchRequest(`${Endpoints.USER}/${id}`, {
          roleId: Number(values.role.id),
          ...diff,
        })
          .then(() => {
            setIsEditMode(false);
            toast.success(translate("toastrMessages.successUpdateUser"));
            getData();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      validateOnChange: true,
      validateOnMount: false,
      validateOnBlur: true,
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {user?.name}
              </MDTypography>
              <MDBox>
                {isEditMode ? (
                  <IconButton
                    type="submit"
                    color="success"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    <Done fontSize="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    color="success"
                    onClick={() => {
                      setIsEditMode(true);
                    }}
                  >
                    <Edit fontSize="medium" />
                  </IconButton>
                )}
                {user && <DeleteUser row={user} isUserDetailsPage={true} />}
              </MDBox>
            </MDBox>
            {isEditMode && (
              <UserForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                setFieldValue={rest.setFieldValue}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default UserDetails;
