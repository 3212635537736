import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { FormHelperText } from "@mui/material";
import { useEffect, useRef } from "react";
import { Romanian } from "flatpickr/dist/l10n/ro.js";

import MDInput from "components/mui/MDInput";
import { resetHourDate } from "helpers/petitionsHelpers";
import pxToRem from "assets/theme/functions/pxToRem";
import { CustomEvent } from "interfaces/petitions";

interface Props {
  name?: string;
  variant?: string;
  label?: string;
  value?: any;
  onChange?: (event: any) => void;
  handleOnBlur?: (e: CustomEvent) => void;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  startToday?: boolean;
  input?: {
    [key: string]: any;
  };

  [key: string]: any;
}

function MDDatePicker({
  name,
  variant,
  label,
  value,
  onChange,
  handleOnBlur,
  error,
  startToday,
  helperText,
  fullWidth,
  minDate,
  maxDate,
  input,
  ...rest
}: Props): JSX.Element {
  const defaultMinDate = new Date();
  const flatpickrRef = useRef(null);

  useEffect(() => {
    // reset the date picker on submit
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(value, false);
    }
  }, [value]);

  const handleDateChange = (selectedDates: Date[]) => {
    const selectedDate = selectedDates[0];
    const formattedDate = resetHourDate(selectedDate); // Convert to the desired format

    if (onChange) {
      onChange({
        target: {
          name,
          value: formattedDate,
        },
      });
    }
  };

  const handleBlur = (selectedDates: Date[], dateStr: string) => {
    if (handleOnBlur) {
      handleOnBlur({
        target: {
          name,
          value: dateStr,
        },
      });
    }
  };

  const maxD = new Date();
  maxD.setFullYear(2999);
  const minD = new Date(-1);

  return (
    <>
      <Flatpickr
        {...rest}
        ref={flatpickrRef}
        options={{
          enable: [
            {
              from: startToday ? minDate || defaultMinDate : minD,
              to: maxDate || maxD,
            },
          ],
          defaultDate: value,
          onChange: handleDateChange,
          onClose: handleBlur, //onBlur doesn't exist in Flatpickr
          locale: Romanian,
          altInput: true,
          altFormat: "d M Y",
        }}
        render={({ defaultValue, ...renderProps }, ref: any) => (
          <MDInput
            {...input}
            {...renderProps}
            name={name}
            variant={variant}
            label={label}
            value={value}
            onChange={() => {}}
            error={error}
            fullWidth={fullWidth}
            defaultValue={defaultValue}
            inputRef={ref}
            className={error ? "Mui-error" : ""}
          />
        )}
      />
      {error && (
        <FormHelperText
          sx={{ color: "red", margin: `${pxToRem(3)} ${pxToRem(14)}` }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}

MDDatePicker.defaultProps = {
  input: {},
};

export default MDDatePicker;
