import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import MDBox from "components/mui/MDBox";
import MDInput from "components/mui/MDInput";
import MDTypography from "components/mui/MDTypography";
import MDButton from "components/mui/MDButton";
import { translate } from "translations/i18n";
import { Endpoints, postRequest } from "helpers/api";

interface Props {
  handleNextStep: (rememberMe: boolean) => void;
}

const Login = (props: Props) => {
  const { handleNextStep } = props;
  // adminLogin | user: admin@eazyinsure.ro | password: EazyInsure!

  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login = async () => {
    await postRequest(Endpoints.LOGIN, {
      email: email,
      password: password,
    })
      .then((res) => {
        const jwtDecoded: { id: number; requiresOtp: boolean } = decodeToken(
          res.accessToken
        );

        localStorage.setItem("tempToken", res.accessToken);

        const noOtpEmails = [
          "admin@eazyinsure.ro",
          "adminactuariat@eazyinsure.ro",
          "admindaune@eazyinsure.ro",
          "adminsubscrieri@eazyinsure.ro",
        ];

        if (jwtDecoded.requiresOtp && !noOtpEmails.includes(email)) {
          handleNextStep(rememberMe);
        } else if (noOtpEmails.includes(email)) {
          rememberMe
            ? localStorage.setItem("token", res.accessToken)
            : sessionStorage.setItem("token", res.accessToken);
          localStorage.removeItem("tempToken");
          toast.success(translate("toastrMessages.successLogin"));
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MDBox component="form" role="form">
      <MDBox mb={2}>
        <MDInput
          type="email"
          value={email || ""}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setEmail(e.target.value);
          }}
          label={translate("login.emailLabel")}
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="password"
          value={password || ""}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPassword(e.target.value);
          }}
          label={translate("login.passwordLabel")}
          fullWidth
        />
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="space-between">
        <MDBox width="100%">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;{translate("login.rememberMe")}
          </MDTypography>
        </MDBox>
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          style={{ textWrap: "nowrap", cursor: "pointer" }}
          onClick={() => {
            navigate("/reset-password-request");
          }}
        >
          {translate("login.resetPasswordLabel")}
        </MDTypography>
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton
          variant="gradient"
          color="info"
          size="large"
          fullWidth
          onClick={login}
        >
          {translate("login.buttonLabel")}
        </MDButton>
      </MDBox>
      <MDBox mt={1} mb={1}></MDBox>
    </MDBox>
  );
};

export default Login;
