import React, { ChangeEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Chip } from "@mui/material";
import { FormikErrors } from "formik";

import MDAutocomplete from "components/mui/MDAutocomplete";
import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import { translate } from "translations/i18n";
import { EAdminStatus, IRole, IUser } from "interfaces";
import { Endpoints, getRequest } from "helpers/api";

interface Props {
  values: IUser;
  errors: FormikErrors<IUser>;
  handleChange: (e: ChangeEvent) => void;
  setFieldValue: (field: string, value: string | number | IRole) => void;
}

const UserForm = (props: Props) => {
  const { values, handleChange, errors, ...rest } = props;

  const [roles, setRoles] = useState<IRole[]>([]);

  useEffect(() => {
    getRequest(Endpoints.ROLES)
      .then((res) => {
        setRoles(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <MDBox p={1}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12} sm={4} p={2}>
              <TextField
                name="name"
                variant="outlined"
                label={translate("users.nameInputLabel")}
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} p={2}>
              <TextField
                name="email"
                variant="outlined"
                label={translate("users.emailInputLabel")}
                value={values.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                type={"email"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} p={2}>
              <MDAutocomplete
                name="role"
                variant="outlined"
                label={translate("roles.roleInputLabel")}
                value={values.role}
                options={roles}
                getOptionLabel={(item) => item.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                setFieldValue={rest.setFieldValue}
                error={Boolean(errors.role)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} p={2}>
              <TextField
                name="createdAt"
                variant="outlined"
                label={translate("users.createdAtInputLabel")}
                value={values.createdAt}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4} p={2}>
              <MDAutocomplete
                name="status"
                variant="outlined"
                label={translate("users.statusInputLabel")}
                value={values.status}
                options={Object.values(EAdminStatus)}
                getOptionLabel={(item: string) => item.replaceAll("_", " ")}
                setFieldValue={rest.setFieldValue}
                error={Boolean(errors.status)}
                fullWidth
                disabled={values.status === EAdminStatus.ACCOUNT_CREATED}
              />
            </Grid>
            <Grid item xs={12} sm={4} p={2}>
              <TextField
                name="lastLoggedIn"
                variant="outlined"
                label={translate("users.lastLoginInputLabel")}
                value={values.lastLoggedIn}
                type={"lastLoggedIn"}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MDTypography variant="h6" mb={1}>
            Permisiunile rolului {values.role?.name}
          </MDTypography>
          <MDBox display="flex" gap={1} alignItems="center" flexWrap="wrap">
            {values.role &&
              roles.find((r) => r.id === values.role?.id) &&
              roles
                .find((r) => r.id === values.role?.id)
                .permissions.map((p) => (
                  <Chip label={p.replaceAll("_", " ")} key={p} />
                ))}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UserForm;
