import { ChangeEvent, useEffect } from "react";
import { SelectChangeEvent, Divider, Grid, TextField } from "@mui/material";
import { FormikErrors } from "formik";
import { get } from "lodash";

import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDDropdown from "components/mui/MDDropdown";
import MDDatePicker from "components/mui/MDDatePicker";
import { translate } from "translations/i18n";
import { CustomEvent, IPetition } from "interfaces/petitions";
import {
  EPetitionerType,
  EPetitionInsuranceClass,
  EPetitionObject,
  EPetitionSolicitant,
  EPetitionSource,
  EPetitionType,
  EPetitionUrgency,
} from "types/petitionTypes";
import MDEditor from "components/mui/MDEditor";
import MDDropzone from "components/mui/MDDropzone";
import {
  petitionOptions,
  petitionSolicitantOptions,
} from "helpers/petitionsHelpers";
import MDSearchDuplicatePetitionId from "components/mui/MDSearchDuplicatePetitionId";
import AgentSearch from "components/mui/MDGetAgents";

interface Props {
  handleChange: (e: ChangeEvent) => void;
  handleOnBlur: (e?: ChangeEvent | CustomEvent) => void;
  values: IPetition;
  errors: FormikErrors<IPetition>;
  touched: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<IPetition>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  resetDropzone: boolean;
  resetSearchAgent: boolean;
}

const PetitionForm = (props: Props) => {
  const {
    handleChange,
    handleOnBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    resetDropzone,
    resetSearchAgent,
  } = props;

  useEffect(() => {
    // Reset the values of asfNumber and asfRegistrationDate when solicitant changes
    setFieldValue("asfNumber", "");
    setFieldValue("asfRegistrationDate", "");
    setFieldTouched("asfRegistrationDate", false);
  }, [values.solicitant]);

  const urgencyColors = {
    [EPetitionUrgency.URGENT]: "red",
    [EPetitionUrgency.MEDIU]: "orange",
    [EPetitionUrgency.SCAZUT]: "green",
  };

  const handleDropdownChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name,
        value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleChange(syntheticEvent);
  };

  const handleAutocompleteChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    if (selectedOption) {
      const syntheticEvent = {
        target: {
          name: "originalPetitionId",
          value: selectedOption.value,
        },
      } as unknown as ChangeEvent<HTMLInputElement>;
      handleChange(syntheticEvent);
    }
  };

  const handleAgentChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    if (selectedOption) {
      const syntheticEvent = {
        target: {
          name: "petitionAgentId",
          value: selectedOption.value,
        },
      } as unknown as ChangeEvent<HTMLInputElement>;
      handleChange(syntheticEvent);
    }
  };

  const handleEditorChange = (content: string) => {
    const syntheticEvent = {
      target: {
        name: "petitionContent",
        value: content,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleChange(syntheticEvent);
  };

  const hasError = (name: string) => {
    return !!(get(touched, name) && get(errors, name));
  };

  const displayHelperText = (name: string) => {
    return hasError(name) ? get(errors, name) : "";
  };

  return (
    <MDBox>
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("newPetition.petentTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          {/*Petent*/}
          <TextField
            name="petitionerName"
            variant="outlined"
            label={translate("newPetition.petitionerName")}
            value={values.petitionerName}
            onChange={handleChange}
            onBlur={handleOnBlur}
            error={hasError("petitionerName")}
            helperText={displayHelperText("petitionerName")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerIdentificationNumber"
            variant="outlined"
            label={translate("newPetition.petitionerIdentificationNumber")}
            value={values.petitionerIdentificationNumber}
            onChange={handleChange}
            onBlur={handleOnBlur}
            error={hasError("petitionerIdentificationNumber")}
            helperText={displayHelperText("petitionerIdentificationNumber")}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            name="petitionerAddress"
            variant="outlined"
            label={translate("newPetition.petitionerAddress")}
            value={values.petitionerAddress}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="solicitant"
            label={translate("newPetition.solicitant")}
            value={values.solicitant}
            options={petitionSolicitantOptions}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("solicitant")}
            helperText={displayHelperText("solicitant")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerEmail"
            variant="outlined"
            label={translate("newPetition.petitionerEmail")}
            value={values.petitionerEmail}
            onChange={handleChange}
            onBlur={handleOnBlur}
            error={hasError("petitionerEmail")}
            helperText={displayHelperText("petitionerEmail")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerPhoneNumber"
            variant="outlined"
            label={translate("newPetition.petitionerPhoneNumber")}
            value={values.petitionerPhoneNumber}
            onChange={handleChange}
            onBlur={handleOnBlur}
            error={hasError("petitionerPhoneNumber")}
            helperText={displayHelperText("petitionerPhoneNumber")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="petitionerType"
            label={translate("newPetition.petitionerType")}
            value={values.petitionerType}
            options={EPetitionerType}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("petitionerType")}
            helperText={displayHelperText("petitionerType")}
          />
        </Grid>
      </Grid>
      <Divider />
      {/*Petitie*/}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("newPetition.petitionTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="petitionObject"
            label={translate("newPetition.petitionObject")}
            value={values.petitionObject}
            options={EPetitionObject}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("petitionObject")}
            helperText={displayHelperText("petitionObject")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="policyNumber"
            variant="outlined"
            label={translate("newPetition.policyNumber")}
            value={values.policyNumber}
            onChange={handleChange}
            error={hasError("policyNumber")}
            helperText={displayHelperText("policyNumber")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="claimNumber"
            variant="outlined"
            label={translate("newPetition.claimNumber")}
            value={values.claimNumber}
            onChange={handleChange}
            error={hasError("claimNumber")}
            helperText={displayHelperText("claimNumber")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="petitionType"
            label={translate("newPetition.petitionType")}
            value={values.petitionType}
            options={petitionOptions}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("petitionType")}
            helperText={displayHelperText("petitionType")}
          />
        </Grid>
        {values.petitionType === EPetitionType.DUPLICATE && (
          <Grid item xs={6} sm={8}>
            <MDSearchDuplicatePetitionId
              label={translate("newPetition.originalPetitionId")}
              onChange={handleAutocompleteChange}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="insuranceClass"
            label={translate("newPetition.insuranceClass")}
            value={values.insuranceClass}
            options={EPetitionInsuranceClass}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("insuranceClass")}
            helperText={displayHelperText("insuranceClass")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDatePicker
            name="receptionDate"
            variant="outlined"
            label={translate("newPetition.receptionDate")}
            value={values.receptionDate}
            maxDate={new Date()}
            minDate={new Date(-1)}
            startToday
            onChange={handleChange}
            handleOnBlur={handleOnBlur}
            error={hasError("receptionDate")}
            helperText={displayHelperText("receptionDate")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MDEditor
            name="petitionContent"
            label={translate("newPetition.petitionContent")}
            value={values.petitionContent}
            onChange={handleEditorChange}
            handleOnBlur={handleOnBlur}
            error={hasError("petitionContent")}
            helperText={displayHelperText("petitionContent")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="inputChannel"
            label={translate("newPetition.inputChannel")}
            value={values.inputChannel}
            options={EPetitionSource}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("inputChannel")}
            helperText={displayHelperText("inputChannel")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="urgency"
            label={translate("newPetition.urgency")}
            value={values.urgency}
            options={EPetitionUrgency}
            colorMap={urgencyColors}
            onChange={handleDropdownChange}
            handleOnBlur={handleOnBlur}
            error={hasError("urgency")}
            helperText={displayHelperText("urgency")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDatePicker
            name="internalDeadline"
            variant="outlined"
            label={translate("newPetition.internalDeadline")}
            value={values.internalDeadline}
            onChange={handleChange}
            handleOnBlur={handleOnBlur}
            error={hasError("internalDeadline")}
            helperText={displayHelperText("internalDeadline")}
            minDate={new Date()}
            startToday={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <AgentSearch
            name="petitionAgentId"
            shouldResetInput={resetSearchAgent}
            selectedAgentId={+values.petitionAgentId}
            label={translate("newPetition.petitionAgentId")}
            onChange={handleAgentChange}
          />
        </Grid>
        {values.solicitant === EPetitionSolicitant.THIRD_PARTY && (
          <>
            <Grid item xs={6} sm={4}>
              <TextField
                name="asfNumber"
                variant="outlined"
                label={translate("newPetition.asfNumber")}
                value={values.asfNumber}
                onChange={handleChange}
                onBlur={handleOnBlur}
                error={hasError("asfNumber")}
                helperText={displayHelperText("asfNumber")}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <MDDatePicker
                name="asfRegistrationDate"
                variant="outlined"
                label={translate("newPetition.asfRegistrationDate")}
                value={values.asfRegistrationDate}
                onChange={handleChange}
                maxDate={new Date()}
                handleOnBlur={handleOnBlur}
                error={hasError("asfRegistrationDate")}
                helperText={displayHelperText("asfRegistrationDate")}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      <Divider />
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("newPetition.uploadDocuments.title")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <Grid item xs={4} sm={4}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.petitionClientFileTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) =>
                setFieldValue("petitionClientFile", files)
              }
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.notaFundamentareTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) =>
                setFieldValue("notaFundamentare", files)
              }
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.mesajAsfTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) => setFieldValue("mesajAsf", files)}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default PetitionForm;
