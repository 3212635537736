import React, { useEffect, useState } from "react";
import {
  ArrowBack,
  ArrowForward,
  Autorenew,
  Check,
  HourglassTop,
  Visibility,
} from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EApprovalType } from "@DCraig-Asig/eazy-resources/dist/enums/accounting/approval-type.enum";
import { addDays, subMonths } from "date-fns";
import { EAdminPermission } from "@DCraig-Asig/eazy-resources";

import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/mui/MDBox";
import MDTypography from "../../components/mui/MDTypography";
import MDButton from "../../components/mui/MDButton";
import DataTable from "../../components/Tables/DataTable";
import {
  IMonthClosing,
  inhidereLunaPrecedentaTableColumns,
} from "../../types/inchidereLunaTypes";
import {
  Endpoints,
  getRequest,
  patchRequest,
  postRequest,
} from "../../helpers/api";
import { formatDate } from "../../helpers/petitionsHelpers";
import { formatDateWithLocale } from "../../utils";
import { translate } from "../../translations/i18n";
import MDDatePicker from "../../components/mui/MDDatePicker";
import { IUser } from "../../interfaces";
import IconButton from "@mui/material/IconButton";

const Tag = (props: any) => {
  const { status } = props;

  return (
    <MDBox
      borderRadius="8px"
      bgColor={
        status === "waiting"
          ? "#DEE2E8"
          : status === "approved"
          ? "rgba(76, 175, 80, 0.2)"
          : ""
      }
      display="flex"
      alignItems="center"
      gap={0.5}
      padding="4px 8px"
    >
      {status === "waiting" && (
        <HourglassTop
          style={{
            color:
              status === "waiting"
                ? "#7B809A"
                : status === "approved"
                ? "#4CAF50"
                : "",
            height: 13,
          }}
        />
      )}
      {status === "approved" && (
        <Check
          style={{
            color:
              status === "waiting"
                ? "#7B809A"
                : status === "approved"
                ? "#4CAF50"
                : "",
            height: 13,
          }}
        />
      )}
      <MDTypography
        style={{
          color:
            status === "waiting"
              ? "#7B809A"
              : status === "approved"
              ? "#4CAF50"
              : "",
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        {status === "waiting"
          ? "ÎN AȘTEPTARE"
          : status === "approved"
          ? "APROBAT"
          : ""}
      </MDTypography>
    </MDBox>
  );
};

const SchimbaDateInchidere = (props: { monthClosing: IMonthClosing }) => {
  const { monthClosing } = props;

  const [regenerateRequested, setRegenerateRequested] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [masterAdmins, setMasterAdmins] = useState<string[]>([]);

  const [activeMonthClosing, setActiveMonthClosing] =
    useState<IMonthClosing>(monthClosing);

  const navigate = useNavigate();

  const handleRegenerate = () => {
    setLoading(true);
    postRequest(Endpoints.ACCOUNTING_MANAGEMENT.REFRESH).then(async () => {
      await getRequest(Endpoints.ACCOUNTING_MANAGEMENT.GET_ACTIVE).then(
        (res) => {
          setActiveMonthClosing(res);
        }
      );

      setLoading(false);
    });
  };

  useEffect(() => {
    getRequest(
      `${Endpoints.USER}?permission=${EAdminPermission.MONTH_CLOSING_MASTER_APPROVE}`
    ).then((res) => {
      setMasterAdmins(res.map((ma: IUser) => ma.name));
    });
  }, []);

  const handleMasterApprove = async () => {
    await patchRequest(Endpoints.ACCOUNTING_MANAGEMENT.APPROVE, {
      type: EApprovalType.MASTER,
    }).then(() => {
      navigate(`/inchiderelunadetails/${activeMonthClosing.id}`);
    });
  };

  const statuses = [
    {
      status: activeMonthClosing.underwritingApproval ? "approved" : "waiting",
      title: "Subscriere",
      approvedBy:
        activeMonthClosing.approvals.find((a) => a.type === "UNDERWRITING")
          ?.name || "-",
    },
    {
      status: activeMonthClosing.claimApproval ? "approved" : "waiting",
      title: "Daune",
      approvedBy:
        activeMonthClosing.approvals.find((a) => a.type === "CLAIM")?.name ||
        "-",
    },
    {
      status: activeMonthClosing.actuarialApproval ? "approved" : "waiting",
      title: "Actuariat",
      approvedBy:
        activeMonthClosing.approvals.find((a) => a.type === "ACTUARIAL")
          ?.name || "-",
    },
  ];

  return (
    <Grid container gap={3}>
      <Grid item xs={4}>
        <MDBox pt={2} pb={2}>
          <Card>
            <MDBox p={3} display="flex" flexDirection="column" gap={3}>
              <MDTypography variant="h5" fontWeight="medium">
                Status Departamente
              </MDTypography>
              {statuses.map((status, index) => (
                <MDBox
                  key={index}
                  bgColor="#F8F9FA"
                  p={3}
                  borderRadius="8px"
                  display="flex"
                  gap={2}
                  flexDirection="column"
                >
                  <MDBox display="flex" justifyContent="space-between">
                    <MDTypography variant="body2" fontWeight="medium">
                      {status.title}
                    </MDTypography>
                    <Tag status={status.status} />
                  </MDBox>
                  <MDTypography style={{ fontSize: 12 }}>
                    Aprobat de: <b>{status.approvedBy}</b>
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
            <div>
              {!regenerateRequested && (
                <MDButton
                  variant="text"
                  color="error"
                  size="small"
                  startIcon={<Autorenew />}
                  style={{ marginBottom: 8 }}
                  onClick={() => {
                    setRegenerateRequested(true);
                  }}
                >
                  REGENERARE NOTE CONTABILE
                </MDButton>
              )}
            </div>
          </Card>
        </MDBox>
      </Grid>
      <Grid item xs={3}>
        <MDBox pt={2} pb={2} display="flex" flexDirection="column" gap={2}>
          <Card>
            <MDBox p={3} display="flex" flexDirection="column" gap={1}>
              <MDTypography
                style={{ fontSize: 14, fontWeight: 700, color: "#7B809A" }}
              >
                Data selectată pentru închiderii de lună
              </MDTypography>
              <MDTypography
                style={{ fontSize: 20, fontWeight: 700, color: "#344767" }}
              >
                {formatDate(monthClosing?.closingDate)}
              </MDTypography>
              <MDTypography
                style={{ fontSize: 14, fontWeight: 400, color: "#7B809A" }}
              >
                Pentru luna{" "}
                <span style={{ color: "#344767", fontWeight: 700 }}>
                  {formatDateWithLocale(
                    subMonths(
                      new Date(activeMonthClosing?.closingDate),
                      1
                    ).toISOString(),
                    "MMMM yyyy"
                  )}
                </span>
              </MDTypography>
            </MDBox>
          </Card>
          <Card>
            {regenerateRequested ? (
              <MDBox p={3} display="flex" flexDirection="column" gap={1}>
                <MDTypography
                  style={{ fontSize: 16, fontWeight: 700, color: "#344767" }}
                >
                  Regenerare Note Contabile
                </MDTypography>
                <MDTypography
                  style={{ fontSize: 16, fontWeight: 400, color: "#7B809A" }}
                >
                  Dacă au fost constatate erori în notele contabile, apasă
                  butonul de mai jos pentru a le regenera.
                  <br />
                  Fiecare departament va trebui să își aprobe din nou datele.
                </MDTypography>
                <MDButton
                  style={{ marginBottom: 8, marginTop: 8 }}
                  startIcon={<Autorenew />}
                  color="error"
                  variant="gradient"
                  onClick={() => {
                    handleRegenerate();
                  }}
                  disabled={loading}
                >
                  REGENERARE NOTE CONTABILE
                </MDButton>
                <div>
                  <MDButton
                    startIcon={<ArrowBack />}
                    color="secondary"
                    variant="text"
                    disabled={loading}
                    onClick={() => {
                      setRegenerateRequested(false);
                    }}
                  >
                    REGENERARE NOTE CONTABILE
                  </MDButton>
                </div>
              </MDBox>
            ) : (
              <MDBox p={3} display="flex" flexDirection="column" gap={1}>
                <MDTypography
                  style={{ fontSize: 16, fontWeight: 700, color: "#344767" }}
                >
                  Aprobare Închidere de Lună Master Admin
                </MDTypography>
                <MDTypography
                  style={{ fontSize: 12, fontWeight: 400, color: "#7B809A" }}
                >
                  Master Admins:{" "}
                  <span style={{ color: "#344767", fontWeight: 700 }}>
                    {masterAdmins.join(", ")}
                  </span>
                </MDTypography>
                <MDButton
                  endIcon={<ArrowForward />}
                  disabled={
                    statuses.filter((s) => s.status === "approved").length !==
                    statuses.length
                  }
                  color={
                    statuses.filter((s) => s.status === "approved").length ===
                    statuses.length
                      ? "success"
                      : "secondary"
                  }
                  variant="gradient"
                  onClick={() => {
                    handleMasterApprove();
                  }}
                >
                  APROBĂ ÎNCHIDEREA DE LUNĂ
                </MDButton>
              </MDBox>
            )}
          </Card>
        </MDBox>
      </Grid>
    </Grid>
  );
};

const InchidereLuna = () => {
  const [closingDate, setClosingDate] = useState<Date>(new Date());

  const [loading, setLoading] = useState<boolean>(false);

  const [activeMonthClosing, setActiveMonthClosing] = useState<IMonthClosing>();
  const [lastMonthClosing, setLastMonthClosing] = useState<IMonthClosing>();
  const [prevMonthClosings, setPrevMonthClosings] = useState<IMonthClosing[]>(
    []
  );

  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const getActiveMonthClosing = () => {
    setLoading(true);
    return getRequest(Endpoints.ACCOUNTING_MANAGEMENT.GET_ACTIVE)
      .then((res) => {
        res.id && setActiveMonthClosing(res);
        return res.id ? res : null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const activeMonthClosing = await getActiveMonthClosing();

      !activeMonthClosing &&
        getRequest(Endpoints.ACCOUNTING_MANAGEMENT.GET_PREVIOUS).then((res) => {
          const pmcListSorted = res?.data?.sort(
            (a: IMonthClosing, b: IMonthClosing) => {
              if (new Date(a.closingDate) > new Date(b.closingDate)) {
                return -1;
              } else if (new Date(a.closingDate) < new Date(b.closingDate)) {
                return 1;
              }

              return 0;
            }
          );

          setPrevMonthClosings(pmcListSorted);
          const lastMonthClosing = !!pmcListSorted.length && pmcListSorted[0];

          if (lastMonthClosing) {
            setLastMonthClosing(lastMonthClosing);
          }
        });
    };

    fetchData()
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const initializeMonthClosing = () => {
    if (closingDate > new Date()) {
      toast.error("Data trebuie sa fie in trecut");
      setError(true);
      return;
    }

    setLoading(true);

    closingDate &&
      postRequest(Endpoints.ACCOUNTING_MANAGEMENT.INIT_MONTH_CLOSING, {
        closingDate: new Date(closingDate).toISOString(),
      })
        .then(() => {
          getActiveMonthClosing();
        })
        .catch(() => {
          setLoading(false);
        });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loading ? (
        <></>
      ) : activeMonthClosing ? (
        <SchimbaDateInchidere monthClosing={activeMonthClosing} />
      ) : (
        <Grid container>
          <Grid item xs={8}>
            <MDBox pt={6} pb={3}>
              <MDBox mb={3} display="flex" flexDirection="column" gap={3}>
                <Card>
                  <MDBox p={3} gap={1} display="flex" flexDirection="column">
                    <MDTypography variant="h5" fontWeight="medium">
                      Data închiderii de lună
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="light">
                      Alege data pentru închiderea de lună. <br />
                      *** Data închiderii precedente este data minimă de
                      raportare pentru luna curentă.
                    </MDTypography>
                  </MDBox>

                  <MDBox p={3} gap={1}>
                    <MDDatePicker
                      name="resolutionDate"
                      variant="outlined"
                      label={translate(
                        "petitionDetails.petitionUpdates.resolutionDate"
                      )}
                      value={closingDate}
                      onChange={(newValue) => {
                        setClosingDate(newValue.target.value);
                      }}
                      startToday
                      minDate={formatDateWithLocale(
                        addDays(
                          new Date(lastMonthClosing?.closingDate || null),
                          1
                        ).toISOString(),
                        "yyyy-MM-dd"
                      )}
                      maxDate={formatDateWithLocale(
                        new Date().toISOString(),
                        "yyyy-MM-dd"
                      )}
                      error={error}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox px={3} pb={3} textAlign="right">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        initializeMonthClosing();
                      }}
                      disabled={loading || error || !closingDate}
                    >
                      Continua
                    </MDButton>
                  </MDBox>
                </Card>
                <Card>
                  <MDBox p={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Închideri de lună precedente
                    </MDTypography>
                  </MDBox>
                  <DataTable
                    canSearch
                    isSorted
                    hasSorting={false}
                    table={{
                      columns: inhidereLunaPrecedentaTableColumns,
                      rows: prevMonthClosings?.map((pmc, index) => ({
                        actions: (
                          <IconButton
                            color="info"
                            onClick={() => {
                              pmc.id &&
                                navigate(`/inchiderelunadetails/${pmc.id}`);
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        ),
                        dataInchiderii: formatDateWithLocale(
                          pmc.closingDate,
                          "dd MMMM yyyy"
                        ),
                        pentruPerioada: `${
                          index < prevMonthClosings.length - 1
                            ? `${formatDateWithLocale(
                                addDays(
                                  new Date(
                                    prevMonthClosings[index + 1]?.closingDate
                                  ),
                                  1
                                ).toISOString()
                              )} - `
                            : ""
                        }${formatDateWithLocale(pmc.closingDate)}`,
                        aprobatDe: !!pmc.approvals.length
                          ? pmc.approvals.find(
                              (approval) => approval.type === "MASTER"
                            )?.name
                          : "-",
                        totalNoteContabile: pmc.documentsCount,
                      })),
                    }}
                  />
                </Card>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default InchidereLuna;
