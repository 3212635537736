import { useEffect, useState } from "react";
import { Check, FileDownload } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import {
  EAccountingNoteItemizedType,
  EAdminPermission,
} from "@DCraig-Asig/eazy-resources";
import { addDays } from "date-fns";
import { EApprovalType } from "@DCraig-Asig/eazy-resources/dist/enums/accounting/approval-type.enum";

import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/mui/MDBox";
import MDTypography from "../../components/mui/MDTypography";
import MDButton from "../../components/mui/MDButton";
import { IMonthClosing } from "../../types/inchidereLunaTypes";
import MDAlert from "../../components/mui/MDAlert";
import {
  Endpoints,
  getRequest,
  patchRequest,
  postRequest,
} from "../../helpers/api";
import { formatDateWithLocale } from "../../utils";

const InchidereLunaDepAdmin = () => {
  const [notesApproved, setNotesApproved] = useState<boolean>(false);
  const [activeMonthClosing, setActiveMonthClosing] = useState<IMonthClosing>();
  const [lastMonthClosing, setLastMonthClosing] = useState<IMonthClosing>();

  const [department, setDepartment] = useState<
    "Actuariat" | "Daune" | "Subscriere"
  >();

  const [approvedBy, setApprovedBy] = useState<string>();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getRequest(Endpoints.ACCOUNTING_MANAGEMENT.GET_PREVIOUS).then((res) => {
        const pmcListSorted = res?.data?.sort(
          (a: IMonthClosing, b: IMonthClosing) => {
            if (new Date(a.closingDate) > new Date(b.closingDate)) {
              return -1;
            } else if (new Date(a.closingDate) < new Date(b.closingDate)) {
              return 1;
            }

            return 0;
          }
        );

        const lastMonthClosing = !!pmcListSorted.length && pmcListSorted[0];

        if (lastMonthClosing) {
          setLastMonthClosing(lastMonthClosing);
        }
      });

      const activeMonthClosing: IMonthClosing = await getRequest(
        Endpoints.ACCOUNTING_MANAGEMENT.GET_ACTIVE
      );

      activeMonthClosing.id && setActiveMonthClosing(activeMonthClosing);

      await getRequest(`${Endpoints.USER_SESSION}`).then((res) => {
        for (const p of res.role.permissions) {
          const okPermissions = [
            EAdminPermission.MONTH_CLOSING_APPROVE_ACTUARIAL,
            EAdminPermission.MONTH_CLOSING_APPROVE_CLAIMS,
            EAdminPermission.MONTH_CLOSING_APPROVE_UNDERWRITING,
          ];

          const okPermissionsIndex = okPermissions.findIndex((op) => op === p);

          if (okPermissionsIndex >= 0) {
            switch (okPermissionsIndex) {
              case 0:
                setDepartment("Actuariat");
                setApprovedBy(
                  activeMonthClosing?.approvals?.find(
                    (a) => a.type === "ACTUARIAL"
                  )?.name
                );
                return;
              case 1:
                setDepartment("Daune");
                setApprovedBy(
                  activeMonthClosing?.approvals?.find((a) => a.type === "CLAIM")
                    ?.name
                );
                setData(
                  Object.entries(EAccountingNoteItemizedType).filter((n) =>
                    n[0].includes("CLAIM")
                  )
                );

                return;
              case 2:
                setDepartment("Subscriere");
                setApprovedBy(
                  activeMonthClosing?.approvals?.find(
                    (a) => a.type === "UNDERWRITING"
                  )?.name
                );
                setData(
                  Object.entries(EAccountingNoteItemizedType).filter(
                    (n) => !n[0].includes("CLAIM")
                  )
                );
                return;
            }
            break;
          }
        }
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    switch (department) {
      case "Actuariat":
        setNotesApproved(activeMonthClosing?.actuarialApproval);
        return;
      case "Subscriere":
        setNotesApproved(activeMonthClosing?.underwritingApproval);
        return;
      case "Daune":
        setNotesApproved(activeMonthClosing?.claimApproval);
        return;
    }
  }, [activeMonthClosing, department]);

  const approveNoteContabile = async () => {
    const approve = async (type: EApprovalType) => {
      await patchRequest(Endpoints.ACCOUNTING_MANAGEMENT.APPROVE, {
        type,
      }).then(async () => {
        getRequest(Endpoints.ACCOUNTING_MANAGEMENT.GET_ACTIVE).then(
          (res: IMonthClosing) => {
            res.id && setActiveMonthClosing(res);
            setApprovedBy(res?.approvals?.find((a) => a.type === type)?.name);
          }
        );
      });
    };

    switch (department) {
      case "Actuariat":
        await approve(EApprovalType.ACTUARIAL);
        return;
      case "Subscriere":
        await approve(EApprovalType.UNDERWRITING);
        return;
      case "Daune":
        await approve(EApprovalType.CLAIM);
        return;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!activeMonthClosing ? (
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} display="flex" flexDirection="column" gap={0.5}>
              <MDTypography
                style={{ color: "#344767", fontSize: 28, fontWeight: 700 }}
              >
                Nu a fost inițializată nicio închidere de lună
              </MDTypography>
              <MDTypography
                style={{ color: "#7B809A", fontSize: 20, fontWeight: 400 }}
              >
                Revino pentru aprobarea notelor contabile când se va stabili
                data de raportare.
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      ) : (
        <>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDBox display="flex" flexDirection="column" gap={3}>
                  <Card>
                    <MDBox
                      p={3}
                      display="flex"
                      flexDirection="column"
                      gap="30px"
                    >
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <MDBox>
                          <MDTypography
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#344767",
                            }}
                          >
                            Notele Contabile de Aprobat
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#7B809A",
                            }}
                          >
                            {activeMonthClosing?.documentsCount} note contabile
                          </MDTypography>
                        </MDBox>
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="light"
                          onClick={() => {
                            getRequest(
                              `${
                                Endpoints.ACCOUNTING_MANAGEMENT
                                  .DOWNLOAD_ZIP_BY_TYPE
                              }?approvalType=${
                                department === "Actuariat"
                                  ? EApprovalType.ACTUARIAL
                                  : department === "Daune"
                                  ? EApprovalType.CLAIM
                                  : EApprovalType.UNDERWRITING
                              }`
                            ).catch(() => {});
                          }}
                        >
                          DESCARCĂ TOATE
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        {data?.map((n, i) => (
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            py={1}
                            borderBottom={
                              i < data.length - 1 ? "1px solid #F0F2F5" : "none"
                            }
                          >
                            <MDBox>
                              <MDTypography
                                style={{ fontSize: 14, color: "#344767" }}
                              >
                                {n[1]}
                              </MDTypography>
                              <MDTypography
                                style={{ color: "#7B809A", fontSize: 12 }}
                              >
                                CSV
                              </MDTypography>
                            </MDBox>
                            <MDButton
                              size="small"
                              variant="text"
                              color="info"
                              startIcon={<FileDownload />}
                              width="100%"
                              onClick={() => {
                                postRequest(
                                  Endpoints.ACCOUNTING_MANAGEMENT.DOWNLOAD_NOTE,
                                  { type: n[1] }
                                ).catch(() => {});
                              }}
                            >
                              Descarcă
                            </MDButton>
                          </MDBox>
                        ))}
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
              <Grid item xs={5}>
                <MDBox display="flex" flexDirection="column" gap={2}>
                  <Card>
                    <MDBox
                      p={3}
                      display="flex"
                      flexDirection="column"
                      gap={0.5}
                    >
                      <MDBox display="flex" justifyContent="space-between">
                        <MDTypography
                          style={{
                            fontSize: 14,
                            color: "#7B809A",
                            fontWeight: 700,
                          }}
                        >
                          Departamentul
                        </MDTypography>
                        <MDTypography
                          style={{
                            fontSize: 14,
                            color: "#7B809A",
                            fontWeight: 400,
                          }}
                        >
                          Aprobat de:{" "}
                          <span style={{ color: "#344767", fontWeight: 700 }}>
                            {approvedBy || "-"}
                          </span>
                        </MDTypography>
                      </MDBox>
                      <MDTypography
                        style={{
                          fontSize: 20,
                          color: "#344767",
                          fontWeight: 700,
                        }}
                      >
                        {department}
                      </MDTypography>
                    </MDBox>
                  </Card>
                  <Card>
                    <MDBox
                      p={3}
                      display="flex"
                      flexDirection="column"
                      gap={0.5}
                    >
                      <MDBox display="flex" justifyContent="space-between">
                        <MDTypography
                          style={{
                            fontSize: 14,
                            color: "#7B809A",
                            fontWeight: 700,
                          }}
                        >
                          Data închiderii de lună
                        </MDTypography>
                        <MDTypography
                          style={{
                            fontSize: 14,
                            color: "#7B809A",
                            fontWeight: 400,
                          }}
                        >
                          Perioada:{" "}
                          <span style={{ color: "#344767", fontWeight: 700 }}>
                            {!!lastMonthClosing &&
                              `${formatDateWithLocale(
                                addDays(
                                  new Date(
                                    lastMonthClosing?.closingDate || null
                                  ),
                                  1
                                ).toISOString()
                              )} - `}
                            {formatDateWithLocale(
                              activeMonthClosing?.closingDate
                            )}
                          </span>
                        </MDTypography>
                      </MDBox>
                      <MDTypography
                        style={{
                          fontSize: 20,
                          color: "#344767",
                          fontWeight: 700,
                        }}
                      >
                        {formatDateWithLocale(
                          activeMonthClosing?.closingDate,
                          "dd MMMM yyyy"
                        )}
                      </MDTypography>
                    </MDBox>
                  </Card>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    height="100%"
                  >
                    <Card style={{ height: "100%" }}>
                      {!!notesApproved ? (
                        <MDBox
                          p={3}
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          height="100%"
                        >
                          <MDAlert color="success">
                            <MDBox display="flex" gap={1} alignItems="center">
                              <Check style={{ height: 16, color: "white" }} />
                              <MDTypography
                                style={{
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: "white",
                                }}
                              >
                                Notele contabile au fost aprobate.
                              </MDTypography>
                            </MDBox>
                          </MDAlert>
                          <MDTypography
                            style={{
                              fontSize: 16,
                              color: "#7B809A",
                              fontWeight: 400,
                            }}
                          >
                            Încheierea de lună va fi realizată de către Master
                            Admin după ce toate departamentele au aprobat notele
                            contabile.
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <MDBox
                          p={3}
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          height="100%"
                        >
                          <MDTypography
                            style={{
                              fontSize: 18,
                              color: "#344767",
                              fontWeight: 700,
                            }}
                          >
                            Aprobare note contabile
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontSize: 16,
                              color: "#7B809A",
                              fontWeight: 400,
                            }}
                          >
                            Verifică dacă datele afișate sunt corecte și apasă
                            butonul de mai jos pentru a aproba notele contabile
                            pe acest departament.
                          </MDTypography>
                          <MDButton
                            endIcon={<Check />}
                            size="small"
                            variant="gradient"
                            color="success"
                            style={{ marginTop: "auto" }}
                            onClick={() => {
                              approveNoteContabile();
                            }}
                          >
                            APROBĂ NOTELE CONTABILE
                          </MDButton>
                        </MDBox>
                      )}
                    </Card>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default InchidereLunaDepAdmin;
