import React, { ChangeEvent } from "react";
import { Grid, Divider, TextField } from "@mui/material";

import { Endpoints } from "helpers/api";
import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDEditor from "components/mui/MDEditor";
import { translate } from "translations/i18n";
import { IPetition } from "interfaces/petitions";
import {
  EPetitionResolutionEndMode,
  EPetitionSolicitant,
  EPetitionType,
} from "types/petitionTypes";
import MDDocumentListItem from "components/mui/MDDocumentListIrem";
import {
  displayPetitionerTypeLabel,
  displayPetitionInsuranceClassLabel,
  displayPetitionLabel,
  displayPetitionObjectLabel,
  displayPetitionResolutionEndModeLabel,
  displayPetitionResolutionEndReasonLabel,
  displayPetitionSolicitantLabel,
  displayPetitionSourceLabel,
  displayPetitionStatusLabel,
  formatDate,
} from "helpers/petitionsHelpers";
import { downloadFile } from "services/cardPaymentsServices";
import MDSearchDuplicatePetitionId from "components/mui/MDSearchDuplicatePetitionId";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

interface Props {
  petitionData: IPetition;
}

const PetitionContent = (props: Props) => {
  const propsPetitionData = { ...props.petitionData, country: "RO" };

  const handleDownloadFile = (documentId: string, fileName: string) => {
    const url = `${Endpoints.PETITIONS.DOWNLOAD_PETITION_DOCUMENTS(
      propsPetitionData.petitionDocumentId,
      documentId
    )}`;
    downloadFile(url, fileName);
  };

  const handleAutocompleteChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    if (selectedOption) {
      const syntheticEvent = {
        target: {
          name: "originalPetitionId",
          value: selectedOption.value,
        },
      } as unknown as ChangeEvent<HTMLInputElement>;
    }
  };

  return (
    <MDBox>
      {/* Petent Section */}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("petitionDetails.petentSectionTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerName"
            variant="outlined"
            label={translate("newPetition.petitionerName")}
            value={propsPetitionData.petitionerName || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerIdentificationNumber"
            variant="outlined"
            label={translate("newPetition.petitionerIdentificationNumber")}
            value={propsPetitionData.petitionerIdentificationNumber || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerEmail"
            variant="outlined"
            label={translate("newPetition.petitionerEmail")}
            value={propsPetitionData.petitionerEmail || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            name="petitionerAddress"
            variant="outlined"
            label={translate("newPetition.petitionerAddress")}
            value={propsPetitionData.petitionerAddress || ""}
            disabled={true}
            fullWidth
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerPhoneNumber"
            variant="outlined"
            label={translate("newPetition.petitionerPhoneNumber")}
            value={propsPetitionData.petitionerPhoneNumber || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionerType"
            label={translate("newPetition.petitionerType")}
            value={displayPetitionerTypeLabel(propsPetitionData.petitionerType)}
            disabled={true}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
      {/* Provenienta Petitiei Section */}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("petitionDetails.petitionProvenienceSectionTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="country"
            variant="outlined"
            label={translate("newPetition.country")}
            value={propsPetitionData.country || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="receptionDate"
            variant="outlined"
            label={translate("newPetition.receptionDate")}
            value={
              propsPetitionData.receptionDate
                ? formatDate(propsPetitionData.receptionDate)
                : ""
            }
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            name="solicitant"
            label={translate("newPetition.solicitant")}
            value={displayPetitionSolicitantLabel(propsPetitionData.solicitant)}
            disabled={true}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
      {/* Petitie Section */}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("petitionDetails.petitionSectionTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionId"
            variant="outlined"
            label={translate("newPetition.petitionNumber")}
            value={propsPetitionData.petitionId}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionObject"
            label={translate("newPetition.petitionObject")}
            value={displayPetitionObjectLabel(propsPetitionData.petitionObject)}
            disabled={true}
            fullWidth
          />
        </Grid>
        {propsPetitionData.solicitant === EPetitionSolicitant.THIRD_PARTY && (
          <>
            <Grid item xs={6} sm={4}>
              <TextField
                name="asfNumber"
                variant="outlined"
                label={translate("newPetition.asfNumber")}
                value={propsPetitionData.asfNumber}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                name="asfRegistrationDate"
                variant="outlined"
                label={translate("newPetition.asfRegistrationDate")}
                value={propsPetitionData.asfRegistrationDate || ""}
                disabled={true}
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={4}>
          <TextField
            name="internalDeadline"
            variant="outlined"
            label={translate("newPetition.internalDeadline")}
            value={
              propsPetitionData.internalDeadline
                ? formatDate(propsPetitionData.internalDeadline)
                : ""
            }
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionType"
            label={translate("newPetition.petitionType")}
            value={displayPetitionLabel(propsPetitionData.petitionType)}
            disabled={true}
            fullWidth
          />
        </Grid>
        {propsPetitionData.petitionType === EPetitionType.DUPLICATE && (
          <Grid item xs={6} sm={8}>
            <MDSearchDuplicatePetitionId
              id={propsPetitionData.originalPetitionId}
              name="originalPetitionId"
              label={translate("newPetition.originalPetitionId")}
              onChange={handleAutocompleteChange}
              disabled={true}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={4}>
          <TextField
            name="claimNumber"
            variant="outlined"
            label={translate("newPetition.claimNumber")}
            value={propsPetitionData.claimNumber || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        {propsPetitionData.websitePolicyId ?? propsPetitionData.policyNumber ? (
          <Grid item xs={6} sm={4}>
            <TextField
              name="policyNumber"
              variant="outlined"
              label={translate("newPetition.policyNumber")}
              value={
                propsPetitionData.websitePolicyId ??
                propsPetitionData.policyNumber ??
                ""
              }
              disabled={true}
              fullWidth
            />
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={6} sm={4}>
          <TextField
            name="inputChannel"
            label={translate("newPetition.inputChannel")}
            value={displayPetitionSourceLabel(propsPetitionData.inputChannel)}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            name="insuranceClass"
            label={translate("newPetition.insuranceClass")}
            value={displayPetitionInsuranceClassLabel(
              propsPetitionData.insuranceClass
            )}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={12}>
          <MDEditor
            label={translate("petitionDetails.petitionContent.content")}
            disabled={true}
            value={propsPetitionData.petitionContent}
            fullWidth
            minRows={10}
          ></MDEditor>
        </Grid>
      </Grid>
      <Divider />
      {/* Petitie Section */}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("petitionDetails.resolveSectionTitle")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="status"
            variant="outlined"
            label={translate("newPetition.status")}
            value={displayPetitionStatusLabel(propsPetitionData.status) || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionAgentName"
            variant="outlined"
            label={translate("newPetition.petitionAgentName")}
            value={propsPetitionData.agent?.name || ""}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} container alignItems="center">
          <Grid item xs={11} sm={11}>
            <TextField
              name="observations"
              variant="outlined"
              label={translate("petitionDetails.petitionUpdates.observations")}
              value={propsPetitionData.observations || ""}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} sm={1} textAlign="center">
            <Tooltip
              title={translate(
                "petitionDetails.petitionUpdates.observationsInfo"
              )}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="resolutionDate"
            variant="outlined"
            label={translate("newPetition.resolutionDate")}
            value={
              propsPetitionData.resolutionDate
                ? formatDate(propsPetitionData.resolutionDate)
                : ""
            }
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionResolutionEndMode"
            label={translate("newPetition.petitionResolutionEndMode")}
            value={displayPetitionResolutionEndModeLabel(
              propsPetitionData.petitionResolutionEndMode
            )}
            disabled={true}
            fullWidth
          />
        </Grid>
        {propsPetitionData.petitionResolutionEndMode ===
          EPetitionResolutionEndMode.NEINTEMEIAT && (
          <Grid item xs={6} sm={4}>
            <TextField
              name="petitionResolutionEndReason"
              label={translate("newPetition.petitionResolutionEndReason")}
              value={displayPetitionResolutionEndReasonLabel(
                propsPetitionData.petitionResolutionEndReason
              )}
              disabled={true}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Divider />
      {/* Documents Section */}
      <Grid container spacing={2} p={2}>
        <Grid item xs={4} md={4}>
          <MDDocumentListItem
            documents={propsPetitionData.documents}
            handleDownloadFile={handleDownloadFile}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default PetitionContent;
