import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Grid, CircularProgress } from "@mui/material";

import { Endpoints, getRequest } from "helpers/api";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDTabs from "components/mui/MDTabs";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import PetitionContent from "./components/PetitionContent";
// import PetitionClientResponse from "./components/PetitionClientResponse";
import PetitionUpdates from "./components/PetitionUpdates";
import { translate } from "translations/i18n";
import { IPetition } from "interfaces/petitions";
import { PetitionTabIndex } from "helpers/petitionsHelpers";

const PetitionDetails = () => {
  const [petitionDetails, setPetitionDetails] = useState<IPetition | null>(
    null
  );
  const [activeTab, setActiveTab] = useState(
    PetitionTabIndex.PetitionContentTab
  );
  const { petitionId } = useParams();

  const fetchPetitionData = (tabIndex: PetitionTabIndex) => {
    getRequest(`${Endpoints.PETITIONS.PETITION_BY_ID(petitionId)}`)
      .then((response) => {
        setPetitionDetails(response);
        setActiveTab(tabIndex);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPetitionData(activeTab);
  }, [petitionId, activeTab]);

  const tabs = [
    {
      label: `${translate("petitionDetails.titleSection")}`,
      onClick: () => fetchPetitionData(PetitionTabIndex.PetitionContentTab),
      content: <PetitionContent petitionData={petitionDetails} />,
    },
    {
      label: `${translate("petitionDetails.petitionUpdates.title")}`,
      onClick: () => fetchPetitionData(PetitionTabIndex.PetitionUpdatesTab),
      content: <PetitionUpdates petitionData={petitionDetails} />,
    },
    // {
    //   label: `${translate("petitionDetails.petitionClientResponse.title")}`,
    //   onClick: () =>
    //     fetchPetitionData(PetitionTabIndex.PetitionClientResponseTab),
    //   content: <PetitionClientResponse />,
    // },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {petitionDetails ? (
        [
          <MDBox mt={6} mb={2} textAlign="left" key="1">
            <MDTypography variant="h3" fontWeight="bold">
              {translate("petitionDetails.title")} {petitionDetails.petitionId}
            </MDTypography>
          </MDBox>,
          <Grid container key="2">
            <Grid item xs={12} sm={12}>
              <MDBox mt={2} mb={12}>
                <Card>
                  <MDTabs
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabChange={(tabIndex) => setActiveTab(tabIndex)}
                  />
                </Card>
              </MDBox>
            </Grid>
          </Grid>,
        ]
      ) : (
        <MDBox mt={6} mb={2} textAlign="center">
          <MDTypography variant="h3" fontWeight="bold">
            <div>
              Loading...
              <CircularProgress color="inherit" />
            </div>
          </MDTypography>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default PetitionDetails;
